<template>
  <div class="row">
    <div class="col-12 col-sm-12">
      <div class="row">
        <div class="col-12 col-sm-12">
          <h2 class="main-title">Buyback</h2>
        </div>
      </div>

      


      <div class="row mt-4">
        <div class="col-12 col-sm-12">
          <div class="card p-60px">
            <div class="row" v-if="loading == true">
              <div class="col-12 col-sm-12">
                <grid-loader
                  class="expertel-loader"
                  color="#ff502d"
                  :loading="loading"
                  :size="20"
                  sizeUnit="px"
                ></grid-loader>
              </div>
            </div>
            <div class="row" v-if="loading == false">
              <form class="col-12 col-sm-12 settings-form"  ref="form" v-on:submit.prevent="sendQuote">
                <div>
                  
                  <!-- {{ makePhones }} -->
                  <div
                    class="row"
                    v-for="(make, index) in makePhones"
                    :key="index"
                  >
                    <div class="col-12 ol-sm-12 col-md-6 col-lg-3">
                      <div class="input-container">
                        <label for="make">Make</label>
                        <select
                          name="make"
                          id="make"
                          class="form-control"
                          v-model="make.make"
                          :data-row="index"
                          @change="getModels($event, index)"
                          required
                        >
                          <option value="">Select an option</option>
                          <option
                            v-for="(brand, indexMake) in makeOptions"
                            :key="indexMake"
                            :value="brand.brand"
                            
                          >
                            {{ brand.brand }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                      <div class="input-container">
                        <label for="model">Model</label>
                        <div style="float:right" v-if="loadingModels[index]">
                          <grid-loader
                            class="expertel-loaderx"
                            color="#ff502d"
                        
                            :size="3"
                            sizeUnit="px"
                          >
                          </grid-loader>
                        </div>
                        <select
                          name="model"
                          
                          v-bind:id="'model'+'_'+index"
                          class="form-control"
                          v-model="make.model"
                          required
                          @change="updateTotalAfterChange()"
                          
                        >
                          <option value="">Select an option </option>
                          <!--
                          <option
                            v-for="(brand, indexModel) in makeModels[index]"
                            :key="indexModel"
                            :value="brand.model"
                          >
                            {{ brand.model }}
                          </option>
                          -->
                        </select>
                        <input type="hidden" class="form_control" v-bind:id="'model_price'+'_'+index"  v-model="make.price">
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                      <div class="input-container">
                        <label for="condition">Condition</label>
                        <select
                          name="condition"
                          id="condition"
                          class="form-control"
                          v-model="make.condition"
                          required
                        >
                          <option value="">Select an option</option>
                          <option value="Good">Good</option>
                          <option value="Fair">Fair</option>
                          <option value="Poor">Poor</option>
                          <option value="Damaged">Damaged</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                      <div class="input-container quantity-btns">
                        <label for="quantity">Quantity</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="make.quantity"
                          readonly
                          value="1"
                        />
                        <button type="button" class="reduce" v-on:click="setReduceItem(index)">
                          <i class="fa fa-minus" aria-hidden="true"></i>
                        </button>
                        <button type="button" class="add" v-on:click="setAddItem(index)">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-8">
                    <div class="col-12 col-sm-12">
                      <button 
                        type="button" 
                        class="btn button-expertel btn-h48"
                        v-on:click = "addItem"
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i> Add Device
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    
                    <div class="col-12 col-sm-12 col-md-6">
                      <div class="input-container input_custom">
                        <label for="first_name" class='label_form'>Name</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="firstname"
                          id="first_name"
                          required
                        />
                      </div>
                    </div>
                    
                    <!--
                    <div class="col-12 col-sm-12 col-md-6">
                      <div class="input-container input_custom">
                        <label  for="last_name" class='label_form'>Name</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="lastname"
                          id="last_name"
                          required
                        />
                      </div>
                    </div>
                    -->

                     <div class="col-12 col-sm-12 col-md-6">
                      <div class="input-container input_custom">
                        <label for="email" class='label_form'>Email </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="email"
                          id="email"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                   
                    <div class="col-12 col-sm-12 col-md-6">
                      <div class="input-container input_custom">
                        <label for="phone_number" class='label_form'>Phone number</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="phone"
                          id="phone_number"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6">
                      <div class="input-container input_custom">
                        <label for="city" class='label_form'>City </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="city"
                          id="city"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    
                    <div class="col-12 col-sm-12 col-md-6">
                      <div class="input-container input_custom">
                        <label for="province_state" class='label_form'>Province/State</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="state"
                          id="province_state"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6">
                      <div class="input-container input_custom">
                        <label for="company" class='label_form'>Company</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="company"
                          id="company"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-12">
                      <div id="holderResultQuote"></div>
                      <div v-if="!sendingForm">
                        <button type="submit" class="btn button-expertel btn-h48" >
                          Request Quote
                        </button>
                      </div>
                      <div class="fa-1x" v-if="sendingForm">
                          <strong>Sending request...</strong> 
                          <grid-loader
                          class="expertel-loaderx"
                          color="#ff502d"
                        
                          :size="15"
                          sizeUnit="px"
                          ></grid-loader>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
  }
  input[type=number] {
    -moz-appearance:textfield;
    background: #fff;
  }
  input[type=number]:focus,
  .reduce:focus,
  .add:focus {
    outline: none;
  }
  #resultQuote{
    font-size: 1.2rem;
    padding: 10px;
    font-family: Circular,sans-serif;
    font-weight: 700;
    color: #343f63;
  }
  .label_form{
    display: block;
    padding-top: 0.75rem;
  }

  .mb-8{
    margin-bottom: 60px;
  }

  .input_custom{
    padding-top: 0px;
  }
  .settings-form .input-container.quantity-btns .reduce{
    padding-left: 2px;
  }
  .settings-form .input-container.quantity-btns .add{
    padding-right: 0px;
  }
  .input-container label{
    padding-left: 4px;
  }

  .settings-form .input-container:last-child{
    margin-bottom: 15px;
  }
</style>
<style>
  .swal2-popup {
    font-size: 14px;
  }
</style>
<script>
export default {
  props: ["login_data", "id_user"],
  data() {
    return {
      loading: false,
      makeOptions: [],//Put the vaiables selected in the inputs 
      //makeModels: [],
      //user_info: [],
      makePhones: [{ //Save the items if the user add New 
        make: "",
        model: "",
        condition: "",
        quantity: 1,
        price: 0,
      }],
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      city: "",
      state: "",
      company: "",
      total: 0,

      sendingForm : false,

      loadingModels: []
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getMakes();
        this.getDataInfo();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    async getMakes() {

      let domain = window.location.hostname;
      //console.log(domain); //www.example.com  
      let serverType = "staging.";
      if( domain == "iq.expertel.ca")
      {
        serverType = ""; //Its prod
      }

      let self = this;
      await this.axios({
        method: "get",
        url: `https://${serverType}expertel.ca/wp-json/buyback/v1/get-brands`,
        responseType: "json"
      })
        .then(async function(response) {
          if (response.data.error === false) {
            self.makeOptions = response.data.brands;
          } else {
            self.makeToast("ERROR", response.data.message, "danger");
          }
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },

    //Get info
    async getDataInfo () {
      const self = this;
      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/info`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {

          self.firstname = response.data.contact_name,
          //self.lastname = response.data.contact_name,
          self.email = response.data.email,
          self.phone = response.data.phone,
          self.city = response.data.city,
          self.state = response.data.province,
          self.company = response.data.name,
          
          self.loading = false;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },

    //New by gio
    async getModels(event, index) {
      let self = this;
      let brand = event.target.value;
      self.loadingModels[index] = true;

      let domain = window.location.hostname;
      //console.log(domain); //www.example.com  
      let serverType = "staging.";
      if( domain == "iq.expertel.ca")
      {
        serverType = ""; //Its prod
      }
      await this.axios({
        method: "get",
        url: `https://${serverType}expertel.ca/wp-json/buyback/v1/get-models/${brand}`,
        responseType: "json"
      })
        .then(async function(response) {
          if (response.data.error === false) {
            //self.makeModels[index] = (response.data.models);


            let listModels = document.querySelector('#model_'+index);

            //Clean the input price
            document.querySelector('#model_price_'+index).value='';

            //Remove all item with specific classes
            const text = document.querySelectorAll(".item_option_"+index);  
            for (const el of text) {  
              el.parentNode.removeChild(el);  
            }

            //Add the list in select
            response.data.models.forEach(function(value) {
              //console.log(value.model)
              var item = document.createElement('option');
              item.text = value.model.trim();//+" - ("+value.price+")";
              item.value = value.model.trim();
              item.className = "item_option_"+index;
              item.dataset.price = value.price;
              listModels.appendChild(item);
            })
          } else {
            self.makeToast("ERROR", response.data.message, "danger");
          }

          self.loadingModels[index] = "";
          self.loadingModels = [];
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    async addItem(){

      let addNewmakePhones = {
          make: "",
          model: "",
          condition: "",
          quantity: 1,
          price: 0,
        }

      this.makePhones.push(addNewmakePhones);
    },

    setAddItem(index){
      this.makePhones[index].quantity++

      //Add the price in total price
      const sb = document.querySelector('#model_'+index)
      let price = sb.options[sb.selectedIndex].dataset.price;
      this.total = parseFloat(this.total) + parseFloat(price);
    },

    setReduceItem(index){
      if(this.makePhones[index].quantity > 1)
      {
        this.makePhones[index].quantity--

        //Discount the price in total price
        const sb = document.querySelector('#model_'+index)
        let price = sb.options[sb.selectedIndex].dataset.price;
        this.total = parseFloat(this.total) - parseFloat(price);
      }
    },

    //This function recalculate the total when option selected is changed
    updateTotalAfterChange(){
      let self = this;
      let index = 0;
      self.total = 0;
      self.makePhones.forEach(function(value) {
          let modelSelected = value.model
          if( modelSelected.trim != "" )
          {
            let currentQuantity = self.makePhones[index].quantity;
            const sb = document.querySelector('#model_'+index)
            let price = sb.options[sb.selectedIndex].dataset.price;

            //=================== Set the price of phone
            document.getElementById('model_price_'+index).value = price;
            self.makePhones[index].price = price
            //===================
            
            //let single_price = sb.options[sb.selectedIndex].dataset.price;
            self.total = (parseFloat(self.total) + parseFloat(price)) * currentQuantity;
          }
          index++;
      })
      

    },


    async sendQuote() {
      let formData = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phone: this.phone,
        city: this.city,
        state: this.state,
        company: this.company,
        //buybackPhones: JSON.stringify(this.makePhones),
        buybackPhones: (this.makePhones),
        total: this.total
      };

      let min = this.total - (this.total*0.20)
      let max = this.total + (this.total*0.05)
      //console.log(min+" - "+max)

      document.querySelector(".settings-form").reset();
      this.makePhones = [{ //Save the items if the user add New 
        make: "",
        model: "",
        condition: "",
        quantity: 1,
        price: 0,
      }],
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.phone = "";
      this.city = "";
      this.state = "";
      this.company = "";
      this.total = 0;

      let self = this;

      //FROMDATA TO SEND
      //let formData = new FormData();
      //formData.append("list", data);
      let domain = window.location.hostname;
      //console.log(domain); //www.example.com  
      let serverType = "staging.";
      if( domain == "iq.expertel.ca")
      {
        serverType = ""; //Its prod
      }
      
      self.sendingForm = true;
      await this.axios({
        method: "post",
        url: `https://${serverType}expertel.ca/wp-json/buyback/v1/save-quote`,
        responseType: "json",
        data: formData,
      })
      .then(async function(response) {
        if (response.data.error === false) {

            self.$swal({
              icon:'success',
              title: "Request submitted!",
              text: "Estimated Quote: $"+min+" - $"+max+"",
              iconColor: '#343f63',
              //"success"
            })
          } else {
            self.makeToast("ERROR", response.data.message, "danger");
          }
        
        self.sendingForm = false;
      })
      .catch(error => this.makeToast("ERROR", error, "danger"));
      
      
      //console.log(formData);
    }
  }
};
</script>
